import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Icon } from 'react-icons-kit'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrTextField from '../components/FrTextField'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import moment from 'moment';

import api from '../services/api'
import { onlyNumbers, dateSql, paramsToObject, validarCNPJ, validarCPF, formToObject } from '../utils/functions'
import consultaCep from '../services/consultaCep'
import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import FrTableReg from '../components/FrTableReg'
import { ic_analytics_twotone } from 'react-icons-kit/md/ic_analytics_twotone'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import Tabs from '../components/Tabs';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { mTel, mCPF, mCNPJCPF, mCEP, mCNPJ } from '../utils/masks'
import $ from 'jquery'
import FrTable from '../components/FrTable'
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";
import { SettingsPowerSharp } from '@material-ui/icons';
import AutoComp from '../components/AutoComp'
import { useHistory } from 'react-router-dom';


moment.locale('pt-br',
  {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_'),
    monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_')
  })



const swal = withReactContent(Swal)

function Clientes() {
  let history = useHistory()
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [modalMarca, setModalMarca] = useState(false)
  const [modalProdutos, setModalProdutos] = useState(false)
  const [modalDoc, setModalDoc] = useState(false)
  const [comboCidCob, setComboCidCob] = useState([{ value: '', display: '' }])
  const [pos, setPos] = useState(0)
  const [comboCat, setComboCat] = useState([{ value: '', display: '' },{ value: 'A', display: 'A' }, { value: 'A+', display: 'A+' }, { value: 'B', display: 'B' },{ value: 'C', display: 'C' }])
  const [sican, setSican] = useState('N')
  const [ven, setVen] = useState(0)
  const [ven2, setVen2] = useState(0)
  const [seg, setSeg] = useState(0)
  const [cid, setCid] = useState(0)
  const [cat, setCat] = useState('')
  const [envio_cob, setEnvio_cob] = useState('N')
  const [dataArquivos, setDataArquivos] = useState([{ value: '', display: '' }])

  const [dataCon, setDataCon] = useState([{ nome: '', display: '' }])
  const [con_tratamento, setCon_Tratamento] = useState('')
  const [con_nome, setCon_Nome] = useState('')
  const [con_cargo, setCon_Cargo] = useState('')
  const [con_fone_1, setCon_Fone_1] = useState('')
  const [con_dep, setCon_dep] = useState('')
  const [con_cpf, setCon_Cpf] = useState('')
  const [con_ddd, setCon_ddd] = useState('');
  const [con_observacoes, setCon_Observacoes] = useState('')
  const [con_email, setCon_Email] = useState('')
  const [con_msn, setCon_Msn] = useState('')
  const [con_mala_direta, setCon_Mala_Direta] = useState('')
  const [con_ct, setCon_Ct] = useState('')
  const [con_bbm, setCon_Bbm] = useState('')
  const [con_mes, setCon_Mes] = useState('')
  const [con_dia, setCon_Dia] = useState('')

  const [arquivoBMM, setArquivoBBM] = useState('')
  const [mostraArquivoBMM, setMostraArquivoBBM] = useState(false)
  const [arquivoCadastro, setArquivoCadastro] = useState('')
  const [mostraArquivoCadastro, setMostraArquivoCadastro] = useState(false)
  const [showModalBBM, setshowModalBBM] = React.useState(false);
  const [pk_cli, setPk_cli] = useState();
  const [showModalCadastro, setshowModalCadastro] = React.useState(false);

  const [arquivo, setArquivo] = useState('')

  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)

  const [showModal, setShowModal] = useState(false)
  const [modalNegocios, setShowModalNegocios] = useState(false)
  const [comprador, setComprador] = useState(0)
  const [vendedor, setVendedor] = useState(0)

  const [dataLog, setDataLog] = useState([{ display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' },])
  const [comboSimNao, setComboSimNao] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' },])
  const [comboVen, setComboVen] = useState([])
  const [comboSeg, setComboSeg] = useState([])
  const [comboRepresentante, setComboRepresentante] = useState([{ value: '', display: '' }])

  const columnsCon = [{ id: 'NOME', numeric: false, label: 'Nome', align: 'left', minWidth: 200 },
  { id: 'DEPARTAMENTO', numeric: false, label: 'Departamento', align: 'left', minWidth: 170 },
  { id: 'EMAIL', numeric: false, label: 'E-mail', align: 'left', minWidth: 150 },
  { id: 'DDD', numeric: false, label: 'DDD', align: 'left', minWidth: 150 },
  { id: 'FONE_1', numeric: false, label: 'Telefone', align: 'left', minWidth: 120 },
  ]


  const columns = [
    { id: 'arquivo', numeric: false, label: 'Arquivo', align: 'left', minWidth: 300 },
  ]


  const [dataCpr, setDataCpr] = useState([{ display: '' }])
  const [fk_pro, setfk_pro] = useState('')
  const [ficha_bbm, setficha_bbm] = useState('')

  const [dataMar, setDataMar] = useState([{ display: '' }])
  const [marca, setMarca] = useState('')

  const [dataCdo, setDataCdo] = useState([{ display: '' }])
  const [fk_tdo, setfk_tdo] = useState('')
  const [data_entrega, setdata_entrega] = useState('')
  const [especificacao, setespecificacao] = useState('')
  const [comissao_r, setComissao_r] = useState(0);

  const [erroCpfCnpj, setErroCpfCnpj] = useState(false)
  const [erroCpfCon, setErroCpfCon] = useState(false)

  const [erroDupliCnpjCpf, setErroDupliCnpjCpf] = useState(false)
  const [dupliCpfCnpj, setDupliCpfCnpj] = useState('')
  const [erroDupliInscEst, setErroDupliInscEst] = useState(false)

  const [excluidosCon, setExcluidosCon] = useState([])
  const [excluidosCpr, setExcluidosCpr] = useState([])
  const [excluidosMar, setExcluidosMar] = useState([])
  const [excluidosCdo, setExcluidosCdo] = useState([])

  const [comboMes, setComboMes] = useState([{ value: 'jan', display: 'Janeiro' },
  { value: 'fev', display: 'Fevereiro' },
  { value: 'mar', display: 'Março' },
  { value: 'abr', display: 'Abril' },
  { value: 'mai', display: 'Maio' },
  { value: 'jun', display: 'Junho' },
  { value: 'jul', display: 'Julho' },
  { value: 'ago', display: 'Agosto' },
  { value: 'set', display: 'Setembro' },
  { value: 'out', display: 'Outubro' },
  { value: 'nov', display: 'Novembro' },
  { value: 'dez', display: 'Dezembro' },
  ])

  const [comboDia, setComboDia] = useState([{ value: '01', display: '01' },
  { value: '02', display: '02' },
  { value: '03', display: '03' },
  { value: '04', display: '04' },
  { value: '05', display: '05' },
  { value: '06', display: '06' },
  { value: '07', display: '07' },
  { value: '08', display: '08' },
  { value: '09', display: '09' },
  { value: '10', display: '10' },
  { value: '11', display: '11' },
  { value: '12', display: '12' },
  { value: '13', display: '13' },
  { value: '14', display: '14' },
  { value: '15', display: '15' },
  { value: '16', display: '16' },
  { value: '17', display: '17' },
  { value: '18', display: '18' },
  { value: '19', display: '19' },
  { value: '20', display: '20' },
  { value: '21', display: '21' },
  { value: '22', display: '22' },
  { value: '23', display: '23' },
  { value: '24', display: '24' },
  { value: '25', display: '25' },
  { value: '26', display: '26' },
  { value: '27', display: '27' },
  { value: '28', display: '28' },
  { value: '29', display: '29' },
  { value: '30', display: '30' },
  { value: '31', display: '31' },

  ])
  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let conAuxValues = [];


      
      if (query.codigo > 0) {
        setEnableEdit('S')
        // setEnableEdit(await api.getAcessos('Clientes/Fornecedores', 'Alteracao'))
      } else {
        setEnableEdit('S')
        // setEnableEdit(await api.getAcessos('Clientes/Fornecedores', 'Inclusao'))
      }

      setComboVen(await api.getComboData('vendedores'))
      setComboSeg(await api.getComboData('segmento'))
      setComboCid(await api.getComboData('cidade'))
      

      if (query.codigo > 0) {
        setPk_cli(query.codigo)
        setUpdateType(true)
        api.get('/clientes?pk_cli=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])
            
            // setVen(r.data[0].FK_VEN)
            // setVen2(r.data[0].FK_VEN2)
            // setSeg(r.data[0].FK_SEG)
            // setCid(r.data[0].FK_CID)
            console.log(r.data[0].FK_VEN2)
            // setCat(r.data[0].CATEGORIA.trim())
            api.get('/clientes/contatos?pk_cli=' + query.codigo).then(r => {
              console.log(r.data)
              if (r.data.length > 0){

                conAuxValues = r.data || [];
                console.log(conAuxValues)
            
                // for (let i = 0; i < r.data.length; i++) {
                //   conAuxValues[i].cpf = (conAuxValues[i].cpf ? mCPF(conAuxValues[i].cpf) : null)
                // }
                setDataCon(conAuxValues)
              }
            })

          

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  history.push('/macropecas_app/clientes')
                }
              })
          }
        })
      } else {
        setData({ fk_emp: 1, ativo: 'S', DESDE: new Date().toISOString() })
        swal.close()
      }
    }

    fetchData()
  }, [])


  useEffect(() => {
    const fetchCidades = async () => {
      if (data.fk_est > 0) {
        swal.fire({
          html: <FrLoading text="Buscando Cidades..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setComboCid(await api.getComboData('cidades', data.fk_est))
        swal.close()
      }
    }

    fetchCidades()
  }, [data.fk_est])

  useEffect(() => {
    const fetchCidadesCob = async () => {
      if (data.fk_est_cob > 0) {
        swal.fire({
          html: <FrLoading text="Buscando Cidades..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setComboCidCob(await api.getComboData('cidades', data.fk_est_cob))
        swal.close()
      }
    }

    fetchCidadesCob()
  }, [data.fk_est_cob])

  function handleCep(e) {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value) {

        let endereco = 'ENDERECO';
        let bairro = 'BAIRRO';

        
        consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(async r => {
          
            const auxValues = { ...data };
            auxValues[endereco] = r.data.logradouro;
            auxValues[bairro] = r.data.bairro;
            
            // await api.get('/estados?uf=' + r.data.uf).then(r => {
            //   if (r.data.length > 0)
            //     setEst(r.data[0].pk_est)
            // })

            console.log(r.data)
            await api.get('/cidade?nomeExato=' + r.data.localidade).then(r => {
              if (r.data.length > 0)
                setCid(r.data[0].PK_CID)
                console.log(r.data[0])
            })
            
            setData(auxValues)
          
        }).catch(err => {
          // if(err)
            // return swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
        })
      }
    }
  }



  function consistData(data) {
    let required = ['CODIGO_REPRESENTADA', 'RAZAO_SOCIAL', 'ATIVO', 'DESDE' , 'SIMPLESNACIONAL']
    //   'fk_tcl'
    // ]

    
    // Tipo – 3 – Representante:
    // Obrigatórios: Cnpj ou CPF / Razão Social / Endereço / Fone 1 / No mínimo 1 Produto / No mínimo 1 contato

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'RAZAO_SOCIAL') fieldName = 'Razão Social'
      if (curr === 'CODIGO_REPRESENTADA') fieldName = 'Código na Representada'
      if (curr === 'ATIVO') fieldName = 'Ativo'
      if (curr === 'DESDE') fieldName = 'Cliente Desde'
      if (curr === 'SIMPLESNACIONAL') fieldName = 'Optante Pelo Simples Nacional'




      if (!data[curr]) return errors.push(fieldName)



    })


    // if ((data.fk_tcl == 1) || (data.fk_tcl == 2) || (data.fk_tcl == 3))
    //   if ((dataCon[0].nome === '') || (dataCon[0].display === ''))
    //     errors.push('Informe ao menos um contato!')

    // if ((data.fk_tcl == 1) || (data.fk_tcl == 2) || (data.fk_tcl == 3) || (data.fk_tcl == 4))
    //   if ((dataCpr[0].nome === '') || (dataCpr[0].display === ''))
    //     errors.push('Informe ao menos um produto!')

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.excluidosCon = excluidosCon
        auxValues.excluidosCpr = excluidosCpr
        auxValues.excluidosCdo = excluidosCdo
        auxValues.excluidosMar = excluidosMar
        auxValues.comissaor = (comissao_r ? comissao_r / 100 : null)

        if (auxValues.data_fundacao)
          auxValues.data_fundacao = dateSql(auxValues.data_fundacao)
        if (auxValues.data_aut_corretora)
          auxValues.data_aut_corretora = dateSql(auxValues.data_aut_corretora)
        if (auxValues.data_aut_conab)
          auxValues.data_aut_conab = dateSql(auxValues.data_aut_conab)
        if (auxValues.rg_data)
          auxValues.rg_data = dateSql(auxValues.rg_data)

        auxValues.sican = sican
        auxValues.envio_cob = envio_cob

        // auxValues.fk_emp = auth.fk_emp
        
        let errors = consistData(auxValues)
        auxValues.dataCon = dataCon
        auxValues.dataCpr = dataCpr
        auxValues.dataMar = dataMar
        auxValues.dataCdo = dataCdo
        auxValues.cnpj_cpf = onlyNumbers(auxValues.cnpj_cpf)

        console.log(comissao_r)

        // if (data.insc_estadual && !updateType) {
        //   await api.get('/clientes?insc_estadual=' + onlyNumbers(data.insc_estadual) + '&codcli=' + data.pk_cli).then(r => {
        //     if (r.data.length > 0)
        //       errors.push(' Já existe cadastro com esta inscrição estadual!')
        //   })
        // }

        if ((data.cnpj_cpf) && (data.cnpj_cpf.length >= 14) && !updateType) {
          await api.get('/clientes?cnpj_cpf=' + onlyNumbers(data.cnpj_cpf) + '&codcli=' + data.pk_cli + '&insc_estadual=' + onlyNumbers(data.insc_estadual)).then(r => {
            if (r.data.length > 0)
              errors.push(` Já existe cadastro com este ${dupliCpfCnpj} e inscrição estadual!`)
          })
        }

        if (errors.length === 0) {
          if (updateType) {
            api.post('/clientes/edit', auxValues).then(r => {
              // console.log(r)
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    history.push('/macropecas_app/clientes')
                  }
                })
              }
            })
              .catch(r => {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado! ",
                  icon: 'warning',
                })
              })
          } else {
            api.post('/clientes/add', auxValues).then(r => {
              console.log()
              if (r.data == "Added!") {
                swal.fire({
                  title: 'Registro Adicionado!',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    history.push('/macropecas_app/clientes')
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado! ",
                  icon: 'warning',
                })
              }
            })
              .catch(r => {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado! ",
                  icon: 'warning',
                })
              })

          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique inconsistências: " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        history.push('/macropecas_app/clientes')
      }
    })
  }

  const handleChange = (e) => {

    const auxValues = { ...data };
    switch (e.target.name) {
      case 'comissaor':
        setComissao_r(e.target.value)
        break;
      case 'fone':
      case 'fone_1':
      case 'fone_2':
      case 'fone_3':
      case 'fone_4':
      case 'whatsapp':

        auxValues[e.target.name] = mTel(e.target.value)
        break

      case 'cnpj_cpf':
        auxValues[e.target.name] = mCNPJCPF(e.target.value)
        // onlyNumbers(e.target.value).length <= 11 ? mCPF(e.target.value) : mCNPJ(e.target.value)
        break

      case 'cnae':
        auxValues['fk_cna'] = e.target.value;
        break;

      case 'envio_cob':
        if (envio_cob == 'S') {
          setEnvio_cob('N')
        } else {
          setEnvio_cob('S')
        }
        break;

      default: auxValues[e.target.name] = e.target.value;
    }

    if (e.target.name == 'data_aut_corretora') {
      let dataBBM = e.target.value;
      swal.fire({
        title: 'ATENÇÃO',
        text: "Você está informando Validade de Autorização de Corretagem BBM. Confirma a informação?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          auxValues['data_aut_corretora'] = dataBBM;
        } else {
          auxValues['data_aut_corretora'] = null;
        }
      })
    }

    if (e.target.name == 'data_aut_conab') {
      let dataCONAB = e.target.value;
      console.log(dataCONAB)
      if (validaData(dataCONAB)) {
        swal.fire({
          title: 'ATENÇÃO',
          text: "Você está informando Validade de Autorização de Corretagem CONAB. Confirma a informação?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.value) {
            auxValues['data_aut_conab'] = dataCONAB;
          } else {
            auxValues['data_aut_conab'] = null;
          }
        })
      }

    }

    if (e.target.name == 'sican') {
      if (sican == 'S')
        setSican('N')
      else
        swal.fire({
          title: 'ATENÇÃO',
          text: "Você está informando cadastro no SICAN. Confirma a informação?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.value) {
            setSican('S')
          }
        })
    }
    console.log(auxValues)
    setData(auxValues);
  };

  function validaData(valor) {
    // Verifica se a entrada é uma string
    if (typeof valor !== 'string') {
      return false
    }

    console.log(valor)

    // Verifica formado da data
    if (!/^\d{4}\-\d{2}\-\d{2}$/.test(valor)) {
      console.log(valor)
      return false
    }

    // Divide a data para o objeto "data"
    const partesData = valor.split('-')
    const data = {
      ano: partesData[0],
      mes: partesData[1],
      dia: partesData[2]
    }

    // Converte strings em número
    const dia = parseInt(data.dia)
    const mes = parseInt(data.mes)
    const ano = parseInt(data.ano)

    console.log(valor, '123')
    // Dias de cada mês, incluindo ajuste para ano bissexto
    const diasNoMes = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Atualiza os dias do mês de fevereiro para ano bisexto
    if (ano % 400 === 0 || ano % 4 === 0 && ano % 100 !== 0) {
      diasNoMes[2] = 29
    }
    console.log(ano)
    // Verifica se ano maior que 1900
    if (ano < 1900) {
      return false
    }

    // Regras de validação:
    // Mês deve estar entre 1 e 12, e o dia deve ser maior que zero
    if (mes < 1 || mes > 12 || dia < 1) {
      return false
    }
    // Valida número de dias do mês
    else if (dia > diasNoMes[mes]) {
      return false
    }

    // Passou nas validações
    return true
  }

  function mostraModal(e) {
    e.preventDefault()
    setCon_Tratamento('')
    
    setCon_Nome('')
    setCon_Cpf('')
    setCon_Email('')
    setCon_Fone_1('')
    setCon_dep('')
    setCon_ddd('')

    setCon_Dia('')
    setCon_Mes('')
    setCon_Observacoes('')
    setCon_Mala_Direta('')
    setCon_Ct('')
    setCon_Bbm('')

    setfk_pro('')
    setficha_bbm('')

    setMarca('')

    setfk_tdo('')
    setdata_entrega('')
    setespecificacao('')
    setArquivo('')

    setUpdateModal(false)
    setModal(true)
  }

  


  function closeModal(e) {
    e.preventDefault()
    setModal(false)
    setModalDoc(false)
    setModalProdutos(false)
    setModalMarca(false)
  }

  function handleSubmitModalContatos(e) {
    e.preventDefault()
    if ((con_nome)) {
      if (!updatemodal)
        incluiCon(e)
      else
        updateCon(e)
      closeModal(e)
    } else
      swal.fire({
        title: 'Erro!',
        text: `Informe nome!`,
        icon: 'warning',
      })

  }

  /// -------------- ajuste de posição de cursor - impede que va para ultima posição quando aplica o replace do regex
  // useEffect(() => {
  //   let p = pos
  //   if (p == 1 || p == 9) {
  //     p = p + 1
  //   }
  //   if ( p == 4 ){
  //     p = p + 2
  //   }
  //   if (con_fone_1 && modal) {
  //     if (p < con_fone_1.length) {
  //       if (document.getElementById('con_fone_1')) {
  //         document.getElementById('con_fone_1').selectionStart = p;
  //         document.getElementById('con_fone_1').selectionEnd = p;
  //       }
  //     }
  //   }
  // }, [con_fone_1, modal])

 
  /// -------------- ajuste de posição de cursor - impede que va para ultima posição quando aplica o replace do regex


  const handleChangeModal = (e) => {
    var target = e.target
    setPos(value => { return target.selectionStart }) // Capture initial position) 
    if (e.target.name == 'con_tratamento')
      setCon_Tratamento(e.target.value)
    if (e.target.name == 'con_nome')
      setCon_Nome(e.target.value)
    if (e.target.name == 'con_dep')
      setCon_dep(e.target.value)
    if (e.target.name == 'con_cargo')
      setCon_Cargo(e.target.value)
    if (e.target.name == 'con_email')
      setCon_Email(e.target.value)
    if (e.target.name == 'con_ddd')
      setCon_ddd(e.target.value)
    if (e.target.name == 'con_msn')
      setCon_Msn(e.target.value)
    if (e.target.name == 'con_fone_1')
    setCon_Fone_1(e.target.value)
    if (e.target.name == 'con_cpf')
      setCon_Cpf(mCPF(e.target.value))
    if ((e.target.name == 'con_mes') || (e.target.name == 'con_dia')) {
      if (e.target.name == 'con_mes')
        setCon_Mes(e.target.value)
      if (e.target.name == 'con_dia')
        setCon_Dia(e.target.value)
    }
    if (e.target.name == 'con_observacoes')
      setCon_Observacoes(e.target.value)
    if (e.target.name == 'mala_direta') {
      if (con_mala_direta == 'S')
        setCon_Mala_Direta('N')
      else
        setCon_Mala_Direta('S')
    }
    if (e.target.name == 'ct') {
      if (con_ct == 'S')
        setCon_Ct('N')
      else
        setCon_Ct('S')
    }
    if (e.target.name == 'bbm') {
      if (con_bbm == 'S')
        setCon_Bbm('N')
      else
        setCon_Bbm('S')
    }
    if (e.target.name == 'fk_pro')
      setfk_pro(e.target.value)
    if (e.target.name == 'ficha_bbm')
      setficha_bbm(e.target.value)
    if (e.target.name == 'marca')
      setMarca(e.target.value)
    if (e.target.name == 'data_entrega')
      setdata_entrega(e.target.value)
    if (e.target.name == 'especificacao')
      setespecificacao(e.target.value)
    if (e.target.name == 'fk_tdo')
      setfk_tdo(e.target.value)
    if (e.target.name == 'arquivo')
      setArquivo(e.target.value)

  };

  const isDupliData = async (e, id) => {
    e.preventDefault()
    console.log(e, pk_cli)
    let registro;
    let campo = e ? e.target.name : ''
    let valor = e ? onlyNumbers(e.target.value) : ''



    switch (campo) {
      // case 'cnpj_cpf':
      //   if (valor != null && valor != '') {
      //     await api.get('/clientes?cnpj_cpf=' + valor).then(r => {
      //       registro = r.data[0]
      //       console.log(registro.pk_cli)
      //     })
      //     console.log(registro)
      //     if (registro != null && registro.pk_cli != Number(pk_cli)) {
      //       setErroDupliCnpjCpf(true)
      //       if (id == 'cnpj') {
      //         setDupliCpfCnpj('CNPJ')
      //       } else {
      //         setDupliCpfCnpj('CPF')
      //       }
      //       window.setTimeout(function () {
      //         document.getElementById(campo).focus();
      //       }, 0);
      //     } else {
      //       setErroDupliCnpjCpf(false)
      //     }
      //     break;
      //   } else {
      //     setErroDupliCnpjCpf(false)
      //     break;
      //   }
      // case 'insc_estadual':
      //   if (valor != null && valor != '') {
      //     await api.get('/clientes?insc_estadual=' + e.target.value).then(r => {
      //       registro = r.data[0]
      //     })
      //     console.log(registro)
      //     if (registro != null && registro.pk_cli != Number(pk_cli)) {
      //       setErroDupliInscEst(true)
      //       window.setTimeout(function () {
      //         document.getElementById(campo).focus();
      //       }, 0);
      //     } else {
      //       setErroDupliInscEst(false)
      //       break;
      //     }
      //   } else {
      //     setErroDupliInscEst(false)
      //   }
      //   break;
      default:
        return false;
    }
  }


  function incluiCon(e) {
    e.preventDefault();
    let pk = -1
    let item = []

    if (!dataCon) {
      setDataCon([{
        NOME: con_nome,
        DEPARTAMENTO: con_dep,
        EMAIL: con_email,
        FONE_1: con_fone_1,
        DDD: con_ddd,
        operacao: 'I',
        pk: pk,
      }])

    } else {

      var i;
      for (i = 0; i < dataCon.length; i++) {
        item.push({
          NOME: dataCon[i].NOME,
          DEPARTAMENTO: dataCon[i].DEPARTAMENTO,
          EMAIL: dataCon[i].EMAIL,
          FONE_1: dataCon[i].FONE_1,
          DDD: dataCon[i].DDD,
          PK_CCT: dataCon[i].PK_CCT,
          operacao: dataCon[i].operacao,
          pk: dataCon[i].pk,

        })
        pk--
      }
      item.push({
        NOME: con_nome,
        DEPARTAMENTO: con_dep,
        EMAIL: con_email,
        FONE_1: con_fone_1,
        DDD: con_ddd,
        operacao: 'I',
        pk: pk,
      })
      pk--
      setDataCon(item.filter(i => { return i.NOME }))
    }
    console.log(dataCon, item)
  }

  function updateCon(e) {
    e.preventDefault();

    let item = []
    let i

    for (i = 0; i < dataCon.length; i++) {
      if (dataCon[i].pk == pkModal) {

        item.push({
          NOME: con_nome,
          DEPARTAMENTO: con_dep,
          EMAIL: con_email,
          FONE_1: con_fone_1,
          DDD: con_cpf,
          pk: pkModal,
          operacao: (pkModal > 0) ? 'A' : 'I'
        })
      } else {
        item.push({
          NOME: dataCon[i].NOME,
          DEPARTAMENTO: dataCon[i].DEPARTAMENTO,
          EMAIL: dataCon[i].EMAIL,
          FONE_1: dataCon[i].FONE_1,
          DDD: dataCon[i].DDD,
          aniversario: dataCon[i].aniversario,
          observacoes: dataCon[i].observacoes,
          pk: dataCon[i].pk,
          operacao: dataCon[0].operacao
        })
      }

      if (i + 1 == dataCon.length)
        setDataCon(item)
    }

  }



  function escondeModal(e) {
    e.preventDefault();
    setShowModal(false)
  }


  async function buscarDocs(e) {
    e.preventDefault();
    let pk = data.pk_cli
    swal.fire({
      html: <FrLoading text="Buscando arquivos..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })

    await api.get('/clientes/getArquivos?pk_cli=' + pk).then(async r => {
      if (r.data.length > 0)
        setDataArquivos(r.data)
      setShowModal(true)
      swal.close()
    })
  }

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_SEG"] = seg;
      auxValues["NOMESEG"] = ''
      if (seg > 0) {
        await api.get("/segmento?pk_seg=" + seg).then((r) => {
          if (r.data[0]){
            auxValues["NOMESEG"] = r.data[0].NOME;
            setData(auxValues);

          }
        });
      }
    };
    fetchMdc();
  }, [seg]);


  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["CATEGORIA"] = cat;
      auxValues["NOMECAT"] = cat;
      if (cat) {
        setData(auxValues);
      }
    };
    fetchMdc();
  }, [cat]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_CID"] = cid;
      auxValues["NOMECID"] = ''
      if (cid > 0) {
        await api.get("/cidade?pk_cid=" + cid).then((r) => {
          if (r.data[0]){
            auxValues["NOMECID"] = r.data[0].NOME;
            setData(auxValues);

          }
        });
      }
    };
    fetchMdc();
  }, [cid]);

  useEffect(() => {
    console.log("ta chamando ven2", ven2)
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_VEN2"] = ven2;
      auxValues["NOMEVEN2"] = ''
      if (ven2 > 0) {
        await api.get("/vendedores?pk_ven=" + ven2).then((r) => {
          if (r.data[0]){
            auxValues["NOMEVEN2"] = r.data[0].RAZAO_SOC;
            setData(auxValues);

          }
        });
      }
    };
    fetchMdc();
  }, [ven2]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_VEN"] = ven;
      auxValues["NOMEVEN"] = ''
      if (ven > 0) {
        await api.get("/vendedores?pk_ven=" + ven).then((r) => {
          if (r.data[0]){
            auxValues["NOMEVEN"] = r.data[0].RAZAO_SOC;
            setData(auxValues);

          }
        });
      }
    };
    fetchMdc();
  }, [ven]);

  
  async function handleCpfCnpj(e) {
    e.preventDefault();
    let cnpj = (data.CNPJ)
    let id;
    id = 'cnpj';
    if(!validarCNPJ(cnpj)){
      return swal.fire('CNPJ Inválido', 'Verifique o CNPJ!', 'error')
    }
    await api.get('/clientes?cnpj='+onlyNumbers(cnpj)).then(r => {
      if(r.data[0] && r.data[0].PK_CLI !== data.PK_CLI){
        return swal.fire('CNPJ Duplicado!', 'O CNPJ informado ja está cadastrado', 'error')
      }
    })
    
  }



  return (
    <div>

    
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={modal} fullWidth={true} maxWidth={'lg'}>
              <div className="register" hidden={!modal}>
                <div className="fields">

                  <FrTextInput
                    value={con_nome || ''}
                    onChange={handleChangeModal}
                    name="con_nome"
                    id="con_nome"
                    color="#49573B"
                    label="Nome"
                    variant="outlined"
                    size="small"
                    style={{ width: 300 }}
                  />

                  <FrTextInput
                    maxLength='100'
                    value={con_dep || ''}
                    onChange={handleChangeModal}
                    name="con_dep"
                    id="con_dep"
                    color="#49573B"
                    label="Departamento"
                    variant="outlined"
                    size="small"
                    style={{ width: 300 }}
                  />


                  <FrTextInput
                    maxLength='150'
                    value={con_email || ''}
                    onChange={handleChangeModal}
                    name="con_email"
                    id="con_email"
                    color="#49573B"
                    label="E-mail"
                    variant="outlined"
                    size="small"
                    style={{ width: 300 }}
                  />

                  <FrTextInput

                    maxLength='3'
                    value={con_ddd || ''}
                    onChange={handleChangeModal}
                    name="con_ddd"
                    id="con_ddd"
                    color="#49573B"
                    label="DDD"
                    variant="outlined"
                    size="small"
                    style={{ width: 60 }}
                  />
                  <FrTextInput
                    maxLength='15'
                    value={con_fone_1 || ''}
                    onChange={handleChangeModal}
                    name="con_fone_1"
                    id="con_fone_1"
                    color="#49573B"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                  />
                  


                </div>
              </div>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <div className="regButtonsDiv">
                <button onClick={handleSubmitModalContatos}>Salvar</button>
                <button onClick={closeModal}>Cancelar</button>
              </div>

            </div>
          </div>
        </Dialog>
      </div>


      <div className="defaultReg">
        <NavBar />
        <div className="defaultRegContent">
          <div className="defaultHeader">
            <h3>{updateType ? 'Edição' : 'Inclusão'} de Cliente</h3>
            <UserOptions />
          </div>
          <br />
          <form id="regForm" onSubmit={handleSubmit}>

            <div  style={{ paddingTop: '10px', display: 'flex', flexWrap: 'wrap', maxWidth: 1000}}>
              <div style={{marginLeft: 10}}>

                  <FrTextInput
                    maxLength='100'
                    value={data.PK_CLI || ''}
                    onChange={handleChange}
                    name="PK_CLI"
                    id="PK_CLI"
                    color="#49573B"
                    label="Código"
                    variant="outlined"
                    size="small"
                    disabled
                    style={{ width: 80 }}
                  />

                  
                  <FrTextInput
                    maxLength='100'
                    value={data.CODIGO_REPRESENTADA || ''}
                    onChange={handleChange}
                    name="CODIGO_REPRESENTADA"
                      id="CODIGO_REPRESENTADA"
                    color="#49573B"
                    label="Código na Representada"
                    variant="outlined"
                    size="small"
                    style={{ width: 230 }}
                  />
                  
                  <FrSelect
                    value={data.ATIVO || ''}
                    onChange={handleChange}
                    name="ATIVO"
                    id="ATIVO"
                    label='Ativo'
                    data={[{display: "Sim", value: 'S'}, {display: "Não", value: 'N'}]}
                    style={{ width: 250 }}
                  />


                  <FrDatePicker
                    maxLength='10'
                    value={(data.DESDE || '').split('T')[0]}
                    onChange={handleChange}
                    name="DESDE"
                    id="DESDE"
                    color="#49573B"
                    label="Cliente desde"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />
              </div>
              <div style={{  maxWidth: '500px',  }}>
                
                <div style={{ paddingTop: '10px', marginLeft: 10}}>

                  
                  <FrTextInput
                    maxLength='100'
                    value={data.RAZAO_SOCIAL || ''}
                    label="Razão Social"
                    onChange={handleChange}
                    name="RAZAO_SOCIAL"
                    id="RAZAO_SOCIAL"
                    variant="outlined"
                    size="small"
                    style={{ width: 406 }}
                    red="true"
                  />
                  <br/>
                  <FrTextInput
                    maxLength='18'
                    value={mCNPJ(data.CNPJ || '')}
                    onChange={handleChange}
                    name="CNPJ"
                    onBlur={handleCpfCnpj}
                    id="CNPJ"
                    color="#49573B"
                    label="CNPJ"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                    erro={erroCpfCnpj}
                  />

                  <FrTextInput
                    maxLength='25'
                    value={data.INSCRICAO_ESTADUAL || ''}
                    onChange={handleChange}
                    name="INSCRICAO_ESTADUAL"
                    id="INSCRICAO_ESTADUAL"
                    color="#49573B"
                    label="Inscrição Estadual"
                    alt={'Teste'}
                    variant="outlined"
                    size="small"
                    error={erroDupliInscEst}
                    helperText={erroDupliInscEst ? 'Já há cliente com esta Insc. Estadual' : ''}
                    onBlur={isDupliData}
                    style={{ width: 200 }}
                  />
                  <br/>

                  <AutoComp
                    value={data.NOMEVEN || ""}
                    valor={data.FK_VEN || ''}
                    width={406}
                    name="FK_VEN"
                    id="FK_VEN"
                    label='Representante'
                    data={comboVen}
                    setValue={setVen}
                  />
                  <br/>
                  <AutoComp
                    value={data.NOMEVEN2 || ""}
                    valor={data.FK_VEN2 || ''}
                    width={406}
                    name="FK_VEN2"
                    id="FK_VEN2"
                    label='Representante 2'
                    data={comboVen}
                    setValue={setVen2}
                  />


                </div>
              </div>

              <div style={{  maxWidth: '500px' }}>
               

                <div style={{ paddingTop: '10px', marginLeft: 10 }}>
                  <FrTextInput
                    value={data.NOME_FANTASIA || ''}
                    onChange={handleChange}
                    name="NOME_FANTASIA"
                    id="NOME_FANTASIA"
                    color="#49573B"
                    label="Nome Fantasia"
                    variant="outlined"
                    size="small"
                    style={{ width: 406 }}
                  />
                  <br/>
                  <FrTextInput
                    value={data.INSCRICAO_MUNICIPAL || ''}
                    onChange={handleChange}
                    name="INSCRICAO_MUNICIPAL"
                    id="INSCRICAO_MUNICIPAL"
                    color="#49573B"
                    label="Inscrição Municipal"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />

                  <FrTextInput
                    value={data.SUFRAMA || ''}
                    onChange={handleChange}
                    name="SUFRAMA"
                    id="SUFRAMA"
                    color="#49573B"
                    label="Suframa"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />

                  <br />

                  <AutoComp
                    value={data.NOMESEG || ""}
                    valor={data.FK_SEG || ''}
                    width={406}
                    name="FK_SEG"
                    id="FK_SEG"
                    label='Seg. Mercado'
                    data={comboSeg}
                    setValue={setSeg}
                  />


                  <br />
                  <FrSelect
                    value={data.SIMPLESNACIONAL || ''}
                    style={{ width: 280 }}
                    name="SIMPLESNACIONAL"
                    id="SIMPLESNACIONAL"
                    label='Optante Pelo Simples Nacional'
                    data={comboSimNao}
                    onChange={handleChange}
                  />
                  <FrTextInput
                    maxLength='15'
                    value={data.CNAE || ''}
                    onChange={handleChange}
                    name="CNAE"
                    id="CNAE"
                    color="#49573B"
                    label="CNAE"
                    variant="outlined"
                    size="small"
                    style={{ width: 120 }}
                  />
                 
                </div>


                {/* <div hidden={!updateType} className="regButtons">

                  <button onClick={(e) => mostraModalNegocios(e, data.pk_cli, 0)}>
                    Negócios Compra
                  </button>

                  <button onClick={(e) => mostraModalNegocios(e, 0, data.pk_cli)}>
                    Negócios Venda
                  </button>
                </div> */}
              </div>
              <div style={{marginLeft: 10}}>
                <FrTextInput
                    value={data.CEP || ''}
                    onChange={handleChange}
                    name="CEP"
                    id="CEP"
                    onBlur={handleCep}
                    color="#49573B"
                    label="CEP"
                    variant="outlined"
                    size="small"
                    style={{ width: 150 }}
                  />
                <FrTextInput
                    value={data.ENDERECO || ''}
                    onChange={handleChange}
                    name="ENDERECO"
                    id="ENDERECO"
                    color="#49573B"
                    label="Endereço"
                    variant="outlined"
                    size="small"
                    style={{ width: 706 }}
                  />
                  <FrTextInput
                    value={data.NUMERO || ''}
                    onChange={handleChange}
                    name="NUMERO"
                    id="NUMERO"
                    color="#49573B"
                    label="Nr."
                    variant="outlined"
                    size="small"
                    style={{ width: 100 }}
                  />
                  <br/>
                  <FrTextInput
                    value={data.COMPLEMENTO || ''}
                    onChange={handleChange}
                    name="COMPLEMENTO"
                    id="COMPLEMENTO"
                    color="#49573B"
                    label="Complemento"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />
                  <FrTextInput
                    value={data.BAIRRO || ''}
                    onChange={handleChange}
                    name="BAIRRO"
                    id="BAIRRO"
                    color="#49573B"
                    label="Bairro"
                    variant="outlined"
                    size="small"
                    style={{ width: 356 }}
                  />
                  <AutoComp
                    value={data.NOMECID || ""}
                    valor={data.FK_CID || ''}
                    width={400}
                    name="FK_CID"
                    id="FK_CID"
                    label='Cidade'
                    data={comboCid}
                    setValue={setCid}
                  />
                  
                  <br/>
                  <FrTextInput
                    value={data.HOMEPAGE || ''}
                    onChange={handleChange}
                    name="HOMEPAGE"
                    id="HOMEPAGE"
                    color="#49573B"
                    label="Homepage"
                    variant="outlined"
                    size="small"
                    style={{ width: 332 }}
                  />
                  <FrTextInput
                    value={data.DDD1 || ''}
                    onChange={handleChange}
                    name="DDD1"
                    id="DDD1"
                    color="#49573B"
                    label="DDD"
                    variant="outlined"
                    size="small"
                    style={{ width: 60 }}
                  />
                  <FrTextInput
                    value={data.FONE1 || ''}
                    onChange={handleChange}
                    name="FONE1"
                    id="FONE1"
                    color="#49573B"
                    label="Telefone 1"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                  />
                  <FrTextInput
                    value={data.DDD2 || ''}
                    onChange={handleChange}
                    name="DDD2"
                    id="DDD2"
                    color="#49573B"
                    label="DDD"
                    variant="outlined"
                    size="small"
                    style={{ width: 60 }}
                  />
                  <FrTextInput
                    value={data.FONE2 || ''}
                    onChange={handleChange}
                    name="FONE2"
                    id="FONE2"
                    color="#49573B"
                    label="Telefone 2"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                  />
                   <FrTextInput
                    value={data.DDDFAX || ''}
                    onChange={handleChange}
                    name="DDDFAX"
                    id="DDDFAX"
                    color="#49573B"
                    label="DDD"
                    variant="outlined"
                    size="small"
                    style={{ width: 60 }}
                  />
                  <FrTextInput
                    value={data.FAX || ''}
                    onChange={handleChange}
                    name="FAX"
                    id="FAX"
                    color="#49573B"
                    label="Fax"
                    variant="outlined"
                    size="small"
                    style={{ width: 140 }}
                  />
                  <br/>
                  <AutoComp
                    value={data.CATEGORIA || ""}
                    valor={data.CATEGORIA || ''}
                    width={400}
                    name="CATEGORIA"
                    id="CATEGORIA"
                    label='Categoria'
                    data={comboCat}
                    setValue={setCat}
                  />
                  <FrDatePicker
                    value={(data.VALIDADE_CATEGORIA || '').split('T')[0]}
                    onChange={handleChange}
                    name="VALIDADE_CATEGORIA"
                    id="VALIDADE_CATEGORIA"
                    color="#49573B"
                    label="Validade Categoria"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />
                  <FrTextInput
                    value={data.EMAIL_FINANCEIRO || ''}
                    onChange={handleChange}
                    name="EMAIL_FINANCEIRO"
                    id="EMAIL_FINANCEIRO"
                    color="#49573B"
                    label="E-mail NFe"
                    variant="outlined"
                    size="small"
                    style={{ width: 356 }}
                  />
                  <br/>
                  <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <FrTextInput
                    value={data.DESC_REDE_1 || ''}
                    onChange={handleChange}
                    name="DESC_REDE_1"
                    id="DESC_REDE_1"
                    color="#49573B"
                    label="Desc. Rede 3"
                    variant="outlined"
                    size="small"
                    style={{ width: 306 }}
                  />
                  <FrTextInput
                    value={data.DESC_REDE_2 || ''}
                    onChange={handleChange}
                    name="DESC_REDE_2"
                    id="DESC_REDE_2"
                    color="#49573B"
                    label="Desc. Rede 2"
                    variant="outlined"
                    size="small"
                    style={{ width: 306 }}
                  />
                  <FrTextInput
                    value={data.DESC_REDE_3 || ''}
                    onChange={handleChange}
                    name="DESC_REDE_3"
                    id="DESC_REDE_3"
                    color="#49573B"
                    label="Desc. Rede 3"
                    variant="outlined"
                    size="small"
                    style={{ width: 306 }}
                  />
                  </div>
                  <br/>
                  <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <FrTextInput
                    maxLength='200'
                    value={data.OBSERVACAO || ''}
                    onChange={handleChange}
                    name="OBSERVACAO"
                    id="OBSERVACAO"
                    color="#528b46"
                    label="Observações"
                    variant="outlined"
                    size="small"
                    style={{ width: 500 }}
                    multiline
                    rows="3"
                    rowsMax="8"
                    fullWidth
                  />
                  </div>
                  <div style={{width: 800, display: 'flex', justifyContent: 'flex-start'}}>
                    <FrTextInput
                      value={data.MOTIVO_INATIVO || ''}
                      onChange={handleChange}
                      name="MOTIVO_INATIVO"
                      id="MOTIVO_INATIVO"
                      color="#49573B"
                      label="Motivo Inativação"
                      variant="outlined"
                      size="small"
                      style={{ width: 376 }}
                    />
                    <FrTextInput
                      value={data.EMAIL_FINANCEIRO || ''}
                      onChange={handleChange}
                      name="EMAIL_FINANCEIRO"
                      id="EMAIL_FINANCEIRO"
                      color="#49573B"
                      label="E-mail NFe"
                      variant="outlined"
                      size="small"
                      style={{ width: 376 }}
                    />
                  </div>
                </div>

              <div class="row" style={{ paddingTop: '10px', paddingLeft: '30px' }}>
                <div className="grupoEdits">
                  <button onClick={(e) => mostraModal(e)}>
                    <Icon icon={ic_add_box} size={18} />
                    Incluir
                  </button>
              
                  <div >
                    <FrTableReg width={95} setPkModal={setPkModal} setUpdateModal={setUpdateModal} setModal={setModal} setCon_Tratamento={setCon_Tratamento} setCon_Nome={setCon_Nome}
                      setCon_Cargo={setCon_Cargo} setCon_Fone_1={setCon_Fone_1}  setCon_Cpf={setCon_Cpf} setCon_Mes={setCon_Mes} setCon_Dia={setCon_Dia} setCon_Observacoes={setCon_Observacoes} setCon_Email={setCon_Email}
                      setCon_Mala_Direta={setCon_Mala_Direta} setCon_Bbm={setCon_Bbm} setCon_Ct={setCon_Ct} setCon_Msn={setCon_Msn}
                      editar={true} setDataGrid={setDataCon} detail regName="contatos" columns={columnsCon} arrayExcluidos={excluidosCon} setArrayExcluidos={setExcluidosCon} searched={false} codeName="display" page={''} data={dataCon}
                      functionDelete={'/clientes/deletaCon/'} sortable={true} orderBy={'nome'}
                    />

                  </div>
                </div>
              </div>

              <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
                <div style={{ padding: '10px' }} >
                  <div className="defaultHeader">
                    <h3>Arquivos</h3>
                  </div>

                  <div className="FrModalButtonsDiv">
                    <MDBContainer className="text-center">
                      <FrTable setShowModal={setShowModal} buscarDocs={buscarDocs} codeName={'pk_cli'} enableDel={'S'} regName='documentos' documentos={true} columns={columns} data={dataArquivos} />
                    </MDBContainer>

                    <button onClick={escondeModal}> Sair </button>
                  </div>
                </div>
              </Dialog>

            </div>

          </form>
          <div className="regButtonsDiv">
            {enableEdit === 'S' ?
              (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          
            <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
          </div>
          <FooterDelphus />
        </div>
      </div >
    </div>
  );
}

export default Clientes;
